<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.number-positions-each-TOC-typical") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.number-positions-each-TOC-typical") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results.total_school_directorate }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row class="flex-chart">
        <v-col v-for="(op, key) in options" :key="key">
          <v-card class="px-2 pt-1 my-4">
            <div style="height: 270px">
              <chart :option="op" autoresize />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

const legend = {
  show: true,
  top: "30px",
  left: "left",
  orient: "vertical",
  textStyle: { fontFamily: "Almarai" },
};

var allZeros = true;

const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.name;
    var percent = params.percent;
    var value = params.value;
    return params.marker + " " + name + "<br/>" + value + " (" + percent + "%)";
  },
};
export default {
  async mounted() {
   try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }

    this.options = [
      this.option(
        this.$t("reportpublic.TotalSchools"),
        this.results.total_school_directorate,
        this.$t("reportpublic.SchoolsRatio"),
        this.results.open ? this.results.open.school_number : "",
        this.results.typical ? this.results.typical.school_number : ""
      ),
      this.option(
        this.$t("reportpublic.TotalStudents"),
        this.results.total_student_directorate,
        this.$t("reportpublic.StudentsRatio"),
        this.results.open ? this.results.open.student_number : "",
        this.results.typical ? this.results.typical.student_number : ""
      ),
      this.option(
        this.$t("reportpublic.TotalWorkrs"),
        this.results.total_employees_directorate,
        this.$t("reportpublic.WorkersRatio"),
        this.results.open ? this.results.open.employee_number : "",
        this.results.typical ? this.results.typical.employee_number : ""
      ),
    ];
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/directorate-reports/courses-for-each-type?directorate_id=1`)
        .then((response) => {
          this.results = response.data.results;
        })

        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    option(title, subtitle, seria_name, open, typical) {
      return {
        title: [
          {
            text: title,
            subtext: `${subtitle}`,
            left: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: 18,
            },
          },
        ],
        legend: legend,
        toolbox: {
          feature: {
            saveAsImage: {
              title: this.$t("dashboard.save-as-png"),
            },
          },
        },
        series: [
          {
            top: "50px",
            bottom: "50px",
            type: "pie",
            radius: ["1%", "99%"],
            name: seria_name,
            center: ["50%", "70%"],
            data: [
              {
                value: open,
                name: this.$t("reportpublic.Open"),
              },
            
              {
                value: typical,
                name: this.$t("reportpublic.Typical"),
              },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0,0,0,0.5)",
              },
            },
            label: {
              show: true,
              position: "inside",
              formatter: (params) => this.labelFormatter(params, subtitle),
            },
          },
        ],
        tooltip: tooltip,
      };
    },

    labelFormatter(params, total) {
      if (total) {
        return params.value ? params.value : "";
      } else {
        return params.value;
      }
    },
  },

  data() {
    return {
      options: [],
      results: { open: {}, typical: {} },
    };
  },
  components: {
    ReportHeader,
  },
};
</script>
