<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.StatisticsSchoolsAndStudentByType") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable((all = false))"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalSchools") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results.total_summer }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
   
          <table class="report-t">
            <thead>
              <tr>
               <th rowspan="2" style="width:50px">{{ $t("globals.#") }}</th>

                <th rowspan="2">
                  {{ $t("reportpublic.directorate") }}
                </th>
                <th colspan="4">{{ $t("reportpublic.Open") }}</th>
                <th colspan="4">{{ $t("reportpublic.Typical") }}</th>
              </tr>
              <tr>
                <template v-for="index in 2" :key="index">
                  <th>{{ $t("reportpublic.Males") }}</th>
                  <th>{{ $t("reportpublic.Females") }}</th>
                  <th>{{ $t("reportpublic.General") }}</th>
                  <th>{{ $t("reportpublic.Total") }}</th>
                </template>
              </tr>

              <tr></tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in data" :key="index">
                <td>{{index+1}}</td>
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? data.directorate_name_ar
                      : data.directorate_name_en
                  }}
                </td>
                <td class="text-center">{{ data.school_open_male }}</td>
                <td class="text-center">{{ data.school_open_fmale }}</td>
                <td class="text-center">{{ data.school_open_general }}</td>
                <td class="text-center">{{ data.school_open_number }}</td>
           
                <td class="text-center">{{ data.school_typical_male }}</td>
                <td class="text-center">{{ data.school_typical_fmale }}</td>
                <td class="text-center">{{ data.school_typical_general }}</td>
                <td class="text-center">{{ data.school_typical_number }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  {{ this.$t("reportpublic.GrandTotal") }}
                </th>
                <th>{{ totals.total_school_open_male }}</th>
                <th>{{ totals.total_school_open_fmale }}</th>
                <th>{{ totals.total_school_open_general }}</th>
                <th>{{ totals.total_school_open }}</th>
      
                <th>{{ totals.total_school_typical_male }}</th>
                <th>{{ totals.total_school_typical_fmale }}</th>
                <th>{{ totals.total_school_typical_general }}</th>
                <th>{{ totals.total_school_typical }}</th>
              </tr>
            </tfoot>
          </table>
     
      <v-row class="flex-chart">
        <v-col v-for="(data, index) in results.types_data" :key="index">
          <statistics-schools-and-student-by-type-chart
            :chart-data="data"
          ></statistics-schools-and-student-by-type-chart>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import statisticsSchoolsAndStudentByTypeChart from "@/components/reports/statisticsSchoolsAndStudentByTypeChart.vue";
export default {
  components: {
    statisticsSchoolsAndStudentByTypeChart,
    ReportHeader,
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fetchData();
      this.fetchData2();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  async mounted() {},
  methods: {
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/statistics-by-schools-type-student-type-governorate-for-chart`)
        .then((response) => {
          this.results = response.data.results;
          this.types_data = this.results.types_data;
        })

        .catch((error) => {});
    },
    async fetchData2() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/statistics-by-schools-type-student-type`)
        .then((response) => {
          this.results2 = response.data.results;
          this.data = response.data.results.data;
          this.totals = response.data.results.totals;
        })

        .catch((error) => {});
    },
  },
  data() {
    return {
      types_data: [],
      results: [],
      results2: [],
      data: [],
      statistics: [],
      schoolTotalMF: {},
      totalOfStudentsMF: {},
      size: null,
      totals: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
};
</script>
