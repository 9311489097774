<template>
  <VNavigationDrawer
    v-model="collapse"
    :temporary="breakPointXS || breakPointSM"
    :permanent="breakPointMD"
    :rail="expand"
    width="250"
    ref="drawer"
  >
    <div
      class="w-100 logo-box d-flex text-center bg-indigo-darken-2"
      style="overflow: hidden"
    >
      <v-responsive style="flex: 4" class="align-self-center">
        <h3 v-show="!expand || breakPointXS || breakPointSM">
          <span v-if="name">{{ name }}</span>
          <span v-else>{{ $t("globals.system-summer-center") }}</span>
        </h3>
      </v-responsive>
      <div style="flex: 1">
        <v-btn
          v-if="(breakPointMD || breakPointLG) && $i18n.locale == 'ar'"
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="
            expand ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'
          "
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else-if="(breakPointMD || breakPointLG) && $i18n.locale == 'en'"
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="
            expand ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'
          "
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else
          @click="Expand()"
          density="compact"
          variant="text"
          icon="mdi-chevron-double-right"
          class="mt-1"
        ></v-btn>
      </div>
    </div>
    <div class="sidebar-item mt-14">
      <!-- <VDivider /> -->
      <VList
        v-model:opened="open"
        class="sidebar-item"
        active-color="indigo"
        open-strategy="single"
        v-model:selected="selectedList"
        @click="(expand = false), (show = false)"
        @click:select="tempOpenLists = open"
      >
        <div class="summer-camp">
          <VListItem
            class="border-bottom"
            :value="$t('globals.dashboard')"
            min-height="40"
            @click="open = [0]"
            :to="{ name: 'summer_dashboard' }"
          >
            <template v-slot:title>
              <span class="v-select-title">{{ $t("globals.dashboard") }}</span>
            </template>

            <template v-slot:prepend>
              <v-tooltip :text="$t('globals.dashboard')">
                <template v-slot:activator="{ props }">
                  <v-icon v-show="expand == true" v-bind="props">
                    mdi-home
                  </v-icon>
                  <v-icon v-show="expand == false"> mdi-home </v-icon>
                </template>
              </v-tooltip>
            </template>
          </VListItem>
          <VListGroup
            class="border-bottom"
            :value="$t('globals.system-initialize')"
            :fluid="false"
            v-if="
              checkRole([
                'sys_admin',
                'gen_admin',
                'dir_admin',
                'gov_admin',
                'summer_admin',
                'user',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.system-initialize")
                  }}</span>
                </template>

                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.general-initialize')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-cog-outline
                      </v-icon>
                      <v-icon v-show="expand == false">
                        mdi-cog-outline
                      </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup
              :value="$t('globals.general-initialize')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                >
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-cog-refresh-outline</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("globals.general-initialize") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('summer.initialization-supervising-authority')"
                :to="{ name: 'initialization-supervisor' }"
                v-if="
                  CheckGetPermission('summer_center.view_supervisor', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialization-supervising-authority")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.summer-information')"
                :to="{ name: 'summer-school' }"
                v-if="
                  CheckGetPermission('summer_center.view_schoolsummer', [
                    'sys_admin',
                    'gen_admin',
                    'dir_admin',
                    'gov_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.summer-information")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.summer-camp-list')"
                :to="{ name: 'summer-camps-list' }"
                v-if="
                  CheckGetPermission('summer_center.view_schoolsummer', [
                    'sys_admin',
                    'gen_admin',
                    'gov_admin',
                    'dir_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.summer-camp-list")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('subject.subjects')"
                :to="{ name: 'initialization-subjects' }"
                v-if="
                  CheckGetPermission('summer_center.view_subject', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("subject.subjects")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer-sidebar.init-student-talents')"
                :to="{ name: 'talent' }"
                v-if="
                  CheckGetPermission('summer_center.change_talent', [
                    'sys_admin',
                    'gen_admin',
                  ]) ||
                  CheckGetPermission('summer_center.add_talent', [
                    'sys_admin',
                    'gen_admin',
                  ]) ||
                  CheckGetPermission('summer_center.view_talent', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.init-student-talents")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer-sidebar.init-employee-skills')"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'skills' }"
                v-if="
                  CheckGetPermission('summer_center.view_skill', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.init-employee-skills")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.countries')"
                :to="{ name: 'summer-countries' }"
                v-if="
                  CheckGetPermission('globaltable.add_country', ['donotshow'])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("school.countries")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.governorates')"
                :to="{ name: 'summer-governorate' }"
                v-if="
                  CheckGetPermission('globaltable.view_governorate', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("school.governorates")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.directorates')"
                :to="{ name: 'summer-directorate' }"
                v-if="
                  CheckGetPermission('globaltable.view_directorate', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.directorates")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.school-calendar')"
              :fluid="false"
              v-if="
                CheckGetPermission('summer_center.view_calendar', [
                  'sys_admin',
                  'gen_admin',
                ]) ||
                CheckGetPermission('summer_center.view_calendar', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                >
                  <template v-slot:title>
                    <span>{{ $t("globals.school-calendar") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-calendar-month-outline</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('globals.study-years')"
                :to="{ name: 'summer-academic-year' }"
                v-if="
                  CheckGetPermission('globaltable.view_yearofstudy', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="small" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.study-years")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.days')"
                v-if="
                  CheckGetPermission('summer_center.view_daysummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'summerdays' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.days") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_week', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.weekly-data')"
                :to="{ name: 'week' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.weekly-data")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.academic-calendar-data')"
                v-if="
                  CheckGetPermission('summer_center.view_calendar', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'summer-calendar' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.academic-calendar-data")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_vacation', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.add-holidays')"
                :to="{ name: 'vacation' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.add-holidays")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.classes')"
              :fluid="false"
              v-if="
                CheckGetPermission('summer_center.view_level', [
                  'sys_admin',
                  'gen_admin',
                ]) ||
                CheckGetPermission('summer_center.view_leveldivision', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                >
                  <template v-slot:title>
                    <span>{{ $t("globals.classes") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-google-classroom</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.change_level', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ]) ||
                  CheckGetPermission('summer_center.view_level', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :value="$t('summer.levels')"
                :to="{ name: 'level' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.levels")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ]) ||
                  CheckGetPermission('summer_center.change_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :value="$t('globals.division')"
                :to="{ name: 'initialization-divisions' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.division")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.change_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ]) ||
                  CheckGetPermission('summer_center.view_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ]) ||
                  CheckGetPermission('summer_center.add_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.assigning-division-to-levels')"
                :to="{ name: 'assigning-divisions-to-level' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assigning-division-to-levels")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('summer_center.change_studentsummer', [
                'sys_admin',
                'gen_admin',
                'dir_admin',
                'gov_admin',
                'summer_admin',
                'user',
              ]) ||
              CheckGetPermission('summer_center.view_studentsummer', [
                'sys_admin',
                'gen_admin',
                'dir_admin',
                'gov_admin',
                'summer_admin',
                'user',
              ])
            "
            class="border-bottom"
            :value="$t('globals.student-affairs')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.student-affairs")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.student-affairs')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-school
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-school </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>

            <VListGroup
              :value="$t('globals.registration-addmission')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-account-check</v-icon
                    >
                    <span>{{ $t("globals.registration-addmission") }}</span>
                  </template>
                </VListItem>
              </template>

              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('globals.new-registration')"
                :to="{ name: 'summer-student-add' }"
                v-if="
                  CheckGetPermission('summer_center.change_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ]) |
                    CheckGetPermission('summer_center.delete_studentsummer', [
                      'sys_admin',
                      'summer_admin',
                      'user',
                    ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.new-registration")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('summer.move-students')"
                :to="{ name: 'add-student-movable' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.move-students")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('school.show_registered_students')"
                :to="{ name: 'summer-student-list' }"
                v-if="CheckGetPermission('summer_center.view_studentsummer')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("school.show_registered_students")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'talentassigins' }"
                :value="$t('summer.assign-student-skills')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assign-student-skills")
                  }}</span>
                </template>
                <template v-slot:prepend> </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('summer.receiving-curricula')"
                :to="{ name: 'receive-curriculum' }"
                v-if="
                  CheckGetPermission('summer_center.change_employeelevel', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.receiving-curricula")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('summer.dropout-students')"
                :to="{ name: 'dropout' }"
                v-if="
                  CheckGetPermission('summer_center.view_levelstudentyear', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.dropout-students")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.parent')"
              :fluid="false"
              v-if="
                CheckGetPermission('summer_center.view_parentsummer', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-human-male-child</v-icon
                    >
                    <span>{{ $t("globals.parent") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_parentsummer', [
                    'donshow',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('globals.parent-add')"
                :to="{ name: 'parent' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.parent-add")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_parentsummer', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :value="$t('globals.parent-list')"
                :to="{ name: 'parents-list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.parent-list")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-report.daily-check-up')"
            :fluid="false"
            v-if="
              CheckGetPermission('summer_center.view_studentattendancesummer', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
                'user',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-report.daily-check-up")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-report.daily-check-up')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-clipboard-check-multiple
                      </v-icon>
                      <v-icon v-show="expand == false">
                        mdi-clipboard-check-multiple</v-icon
                      >
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup
              :value="$t('summer-sidebar.attendnace-protoflio')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                  v-if="
                    CheckGetPermission(
                      'summer_center.view_studentattendancesummer',
                      ['sys_admin', 'summer_admin', 'user']
                    )
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-account-check</v-icon
                    >
                    <span>{{ $t("summer-sidebar.attendnace-protoflio") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                v-if="
                  CheckGetPermission(
                    'summer_center.view_studentattendancesummer',
                    ['sys_admin', 'summer_admin', 'user']
                  )
                "
                :value="$t('permission.studentattendance')"
                :to="{ name: 'student-attendance-view' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("permission.studentattendance")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_employeeattendance', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :value="$t('summer.employee.attendance')"
                :to="{ name: 'employee-attendance' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.employee.attendance")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.lessons')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-newspaper-variant-multiple</v-icon
                    >
                    <span>{{ $t("summer-sidebar.lessons") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_lecturesummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('lecture.adds')"
                :to="{ name: 'lecture_add' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("lecture.adds")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_lecturesummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('lecture.view')"
                :to="{ name: 'lecture_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("lecture.view")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_lecturelog', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.lesson-record')"
                :to="{ name: 'lesson-record' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.lesson-record")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.visitors')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                  v-if="
                    CheckGetPermission('summer_center.add_visitor', [
                      'sys_admin',
                      'summer_admin',
                      'user',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-clipboard-check-outline</v-icon
                    >
                    <span>{{ $t("summer-sidebar.visitors") }} </span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_visitor', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.add-visitors')"
                :to="{ name: 'add-visitor' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.add-visitors")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_visitor', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.all-visitors')"
                :to="{ name: 'visitor' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.all-visitors")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.activities-intiatives')"
            :fluid="false"
            v-if="
              CheckGetPermission('view_activitytype', [
                'sys_admin',
                'gen_admin',
              ]) ||
              CheckGetPermission('view_activitysummer', [
                'sys_admin',
                'summer_admin',
                'user',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.activities-intiatives")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.activities-intiatives')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-bullseye-arrow
                      </v-icon>
                      <v-icon v-show="expand == false">
                        mdi-bullseye-arrow</v-icon
                      >
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup :value="$t('summer-sidebar.activities')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2">
                      mdi-bullseye-arrow</v-icon
                    >
                    <span>{{ $t("summer-sidebar.activities") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_activitytype', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.initialize-types-activities')"
                :to="{ name: 'type-activity' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialize-types-activities")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_activitysummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :to="{ name: 'add_activity' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.add-activity')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.add-activity")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_activitysummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :to="{ name: 'activity_list' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.view-activity')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.view-activity")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_activityoperation', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'Activity-Opreations' }"
                :value="$t('summer.initialize-activity-processes')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialize-activity-processes")
                  }}</span>
                </template>
              </VListItem>
              <!-- <VListItem
                v-if="CheckGetPermission('summer_center.add_plan',['sys_admin','gen_admin'])"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'Activity-Plane_add' }"
                :value="$t('summer.add-activity-plans')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t('summer.add-activity-plans')
                  }}</span>
                </template>
              </VListItem> -->

              <!-- <VListItem
                v-if="CheckGetPermission('summer_center.view_plan',['sys_admin','gen_admin'])"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'Activity-Plane_list' }"
                :value="$t('summer.show-activity-plans')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t('summer.show-activity-plans')
                  }}</span>
                </template>
              </VListItem> -->

              <VListItem
                v-if="
                  CheckGetPermission(
                    'summer_center.view_schoolweeklyactivity',
                    ['sys_admin', 'gen_admin']
                  )
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.set-weekly-activities')"
                :to="{ name: 'WeeklyActivityAssign' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.set-weekly-activities")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_activitydifficulty', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.activity.difficulties_and_solutions_assign')"
                :to="{ name: 'dicultsandslotions_add' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_assign")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_activitydifficulty', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.activity.difficulties_and_solutions_show')"
                :to="{ name: 'dicultsandslotions_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_show")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_studentactivity', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.assign-student-activities')"
                :to="{ name: 'ActivityStudentAssigin' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assign-student-activities")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_activity-difficulty', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'dicultsandslotions_add' }"
                :value="$t('summer.activity.difficulties_and_solutions_assign')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_assign")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_activity-difficulty', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.activity.difficulties_and_solutions_show')"
                :to="{ name: 'dicultsandslotions_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_show")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.intiatives')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                  v-if="
                    CheckGetPermission('summer_center.view_initiative', [
                      'sys_admin',
                      'summer_admin',
                      'user',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-handshake</v-icon
                    >
                    <span>{{ $t("summer-sidebar.intiatives") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :to="{ name: 'summer_initiative' }"
                v-if="
                  CheckGetPermission('summer_center.view_initiative', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer.add-an-initiative')"
              >
                <!-- :value="$t('summer.initiatives')" -->
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.add-an-initiative")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_initiative', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'initiativeassigin' }"
                :value="$t('summer.assigning-students-initiatives')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assigning-students-initiatives")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.community-committees')"
            :fluid="false"
            v-if="
              CheckGetPermission('add_commitee', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
                'user',
              ]) ||
              CheckGetPermission('add_commitee', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
                'user',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.community-committees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.community-committees')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-human-capacity-increase
                      </v-icon>
                      <v-icon v-show="expand == false"
                        >mdi-human-capacity-increase</v-icon
                      >
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.view_commitee', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'commits-summer' }"
              :value="$t('summer.initialize-committees')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.initialize-committees")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.add_commiteemember', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'membercommit_add' }"
              :value="$t('summer.assign-committees-to-members')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.assign-committees-to-members")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.view_commiteemember', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'membercommit_list' }"
              :value="$t('summer.show-assign-committees-to-members')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.show-assign-committees-to-members")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.study-timetable')"
            :fluid="false"
            v-if="
              CheckGetPermission('summer_center.view_schedule', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
                'user',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.study-timetable")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.study-timetable')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-timetable
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-timetable </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.view_schedule', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'summer-schedule' }"
              :value="$t('summer-sidebar.init-periods')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.init-periods")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission(
                  'summer_center.view_schoolweeklyschedulesummer',
                  ['sys_admin', 'gen_admin', 'summer_admin', 'user']
                )
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'summer_time_table_add' }"
              :value="$t('summer-sidebar.add-timetable')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.add-timetable")
                }}</span>
              </template>
            </VListItem>
            <!-- <VListItem
              v-if="CheckGetPermission('summer_center.change_schoolweeklyschedulesummer')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'summer_time_table_update' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t('summer-sidebar.edit-timetable') }}</span>
              </template>
            </VListItem> -->
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('summer_center.view_employee', [
                'sys_admin',
                'gen_admin',
                'dir_admin',
                'gov_admin',
                'summer_admin',
                'user',
              ])
            "
            class="border-bottom"
            :value="$t('summer-sidebar.personnel-authority')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.personnel-authority")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.personnel-authority')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-account-tie
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-account-tie </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.add_employee', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :value="$t('summer.add-employee')"
              :to="{ name: 'add-employee' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.add-employee")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('summer_center.view_employee')"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :value="$t('summer.employees')"
              :to="{ name: 'employees' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.employees")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.add_teachersubjectsummer', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :value="$t('summer.add-teachers-plan')"
              :to="{ name: 'assign-teacher_add' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.add-teachers-plan")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('summer_center.view_teachersubjectsummer', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :value="$t('summer.view-teachers-plan')"
              :to="{ name: 'assign-teacher_list' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.view-teachers-plan")
                }}</span>
              </template>
            </VListItem>

            <VListItem
              v-if="
                CheckGetPermission('summer_center.view_employee', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :value="$t('summer.manage-state-employee')"
              :to="{ name: 'EmployeeStatusManage' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.manage-state-employee")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'employeeassigin' }"
              :value="$t('summer.assign-employee-skills')"
              v-if="
                CheckGetPermission('summer_center.change_employee', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ]) ||
                CheckGetPermission('summer_center.add_employee', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ]) ||
                CheckGetPermission('summer_center.view_employee', [
                  'sys_admin',
                  'summer_admin',
                  'user',
                ])
              "
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.assign-employee-skills")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('summer_center.add_supplies', [
                'sys_admin',
                'summer_admin',
                'user',
              ])
            "
            :value="$t('summer.weekly-follow-up')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer.weekly-follow-up")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer.weekly-follow-up')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-calendar-week-begin
                      </v-icon>
                      <v-icon v-show="expand == false"
                        >mdi-calendar-week-begin
                      </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup :value="$t('summer-sidebar.statistics')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-chart-box-outline</v-icon
                    >
                    <span>{{ $t("summer-sidebar.statistics") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_managmenttask', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'add-managament-tasks' }"
                :value="$t('summer-sidebar.managament-task-add')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.managament-task-add")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_managmenttask', [
                    'donotshow',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'managament_tasks' }"
                :value="$t('summer-sidebar.managament-task-view')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.managament-task-view")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_recommendation', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'recomendations' }"
                :value="$t('summer-sidebar.positives-negatives')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.positives-negatives")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_approach', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'SubjectsStatics' }"
                :value="$t('summer-sidebar.received-currical')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.received-currical")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer.supplies')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-lifebuoy</v-icon
                    >
                    <span>{{ $t("summer.supplies") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.add_supplies', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'add-supplies' }"
                :value="$t('summer-sidebar.supplies-add')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.supplies-add")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_supplies', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'supplies' }"
                :value="$t('summer-sidebar.supplies-view')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.supplies-view")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.marks')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" color="icon-color" class="me-2"
                      >mdi-counter</v-icon
                    >
                    <span>{{ $t("summer-sidebar.marks") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('summer_center.view_marksummer', [
                    'sys_admin',
                    'summer_admin',
                    'user',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'summer_weekly_marks' }"
                :value="$t('summer-sidebar.weekly-marks')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.weekly-marks")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <GeneralManagementReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></GeneralManagementReport>
          <GovernorateReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></GovernorateReport>
          <DirectorateReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></DirectorateReport>
          <DailyReports
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></DailyReports>
          <SummerCenterReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></SummerCenterReport>
          <VListGroup
            class="title border-bottom"
            v-if="
              CheckGetPermission('usermanage.view_user', [
                'sys_admin',
                'gen_admin',
                'gov_admin',
                'dir_admin',
                'summer_admin',
                'gov_financial',
                'gen_financial',
              ])
            "
            :value="$t('globals.users-adminstration')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.users-adminstration")
                  }}</span>
                </template>

                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.users-adminstration')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-account-multiple
                      </v-icon>
                      <v-icon v-show="expand == false"
                        >mdi-account-multiple
                      </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('usermanage.add_user', [
                  'sys_admin',
                  'gen_admin',
                  'gov_admin',
                  'dir_admin',
                  'summer_admin',
                  'user',
                  'gov_financial',
                  'gen_financial',
                ])
              "
              :value="$t('libraries.add-user')"
              :to="{ name: 'summer_add_user' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("libraries.add-user")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('usermanage.view_user', [
                  'sys_admin',
                  'gen_admin',
                  'gov_admin',
                  'dir_admin',
                  'summer_admin',
                  'user',
                  'gov_financial',
                  'gen_financial',
                ])
              "
              :value="$t('user.view-users')"
              :to="{ name: 'summer_user_list' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("user.view-users")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('usermanage.view_rolesstatus', ['sys_admin','gen_admin'])"
              :value="$t('summer-sidebar.roles-status')"
              :to="{ name: 'roles_status' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.roles-status")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('auth.add_group', [
                  'sys_admin',
                  'gen_admin',
                  'gov_admin',
                  'dir_admin',
                  'summer_admin',
                  'user',
                ])
              "
              :value="$t('group.add_group')"
              :to="{ name: 'summer_add_group' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("group.add_group")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('', [
                  'sys_admin',
                  'gen_admin',
                  'gov_admin',
                  'dir_admin',
                  'summer_admin',
                  'user',
                  'gov_financial',
                  'gen_financial',
                ])
              "
              :value="$t('summer-sidebar.logs')"
              :to="{ name: 'log_entries' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.logs")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            class="title border-bottom"
            v-if="
              CheckGetPermission('public_report.view_summerschoolstatistics', [
                'sys_admin',
                'gen_admin',
                'gov_admin',
                'dir_admin',
                'summer_admin',
                'gov_financial',
                'gen_financial',
              ])
            "
            :value="$t('globals.statistic')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.statistic")
                  }}</span>
                </template>

                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.statistic')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-chart-pie
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-chart-pie </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>

            <VListItem
              :value="$t('statistics.summer-statistic')"
              min-height="40"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              :to="{ name: 'summer_statistic' }"
              :style="{ paddingLeft: $i18n.locale === 'ar' ? '' : '6px' }"
              v-if="
                CheckGetPermission('summer_center.view_schoolsummer', [
                  'sys_admin',
                  'summer_admin',
                  'dir_admin',
                  'gov_admin',
                ]) && school_type != 1
              "
            >
              <template v-slot:title>
                <span>{{ $t("statistics.summer-statistic") }} </span>
              </template>
              <template v-slot:prepend>
                <v-tooltip :text="$t('statistics.summer-statistic')">
                  <template v-slot:activator="{ props }">
                    <v-icon v-show="expand == true" v-bind="props">
                      mdi-chart-bar-stacked
                    </v-icon>
                    <v-icon v-show="expand == false"
                      >mdi-chart-bar-stacked
                    </v-icon>
                  </template>
                </v-tooltip>
              </template>
            </VListItem>
            <VListItem
              v-if="
                checkRole(['sys_admin', 'gen_admin', 'dir_admin', 'gov_admin'])
              "
              :to="{ name: 'summer-school-report-statistic' }"
              :value="this.$t('summer.school-statistics-report')"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span>{{ this.$t("summer.school-statistics-report") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon class="me-2" size="medium"> mdi-chart-pie </v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                checkRole(['sys_admin', 'gen_admin', 'dir_admin', 'gov_admin'])
              "
              :to="{ name: 'school_statistics_attendance' }"
              :value="$t('statistics.check-up-statistics')"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span>{{ this.$t("statistics.check-up-statistics") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon class="me-2" size="medium"> mdi-file-check </v-icon>
              </template>
            </VListItem>
            <VListGroup
              :value="$t('summer-sidebar.statistics-reports')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                >
                  <template v-slot:title>
                    <span>{{ $t("globals.reports") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium" class="me-2">
                      mdi-bullseye-arrow</v-icon
                    >
                  </template>
                </VListItem>
              </template>
              <VListItem
                :to="{ name: 'VisitorsPublicStatistics' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="this.$t('reportpublic.VisitsCarriedOutDuringTheDay')"
              >
                <!-- :to="{ name: 'visitsCarriedOutDuringDay' }" -->
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    this.$t("reportpublic.VisitsCarriedOutDuringTheDay")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :to="{
                  name: 'variousActivitiesDuringSummerCentersPeriodCapital',
                }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="
                  this.$t('reportpublic.SummerTotalActivitiesCarriedOutHeader')
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    this.$t(
                      "reportpublic.SummerTotalActivitiesCarriedOutHeader"
                    )
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="title border-bottom"
            v-if="role >= 0 && role <= 3"
            :value="$t('summer.summer-open')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <dir :title="$t('summer.summer-open')">
                <VListItem v-bind="props" min-height="40">
                  <template v-slot:title>
                    <span class="v-select-title">{{
                      $t("summer.summer-open")
                    }}</span>
                  </template>

                  <template v-slot:prepend>
                    <v-tooltip :text="$t('summer.summer-open')">
                      <template v-slot:activator="{ props }">
                        <v-icon v-show="expand == true" v-bind="props">
                          mdi-home-city
                        </v-icon>
                        <v-icon v-show="expand == false">mdi-home-city </v-icon>
                      </template>
                    </v-tooltip>
                  </template>
                </VListItem>
              </dir>
            </template>

            <VListItem
              :value="$t('summer.summer-open-data')"
              :to="{ name: 'summer_school_open' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer.summer-open-data")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer.summer-open-list')"
              :to="{ name: 'summer_school_open_list' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer.summer-open-list")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            
            <VListItem
              :value="$t('summer-sidebar.employees-list')"
              :to="{ name: 'summer_school_open_employees_list' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.employees-list")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer-sidebar.review-employees')"
              :to="{ name: 'summer_school_open_employees_review' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.review-employees")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer-sidebar.dropedout-froms')"
              :to="{ name: 'open_school_droped_out' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              v-if="
                CheckGetPermission('summer_center.view_studentsummer', [
                  'sys_admin',
                  'gen_admin',
                  'gov_admin'
                ])
              "
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.dropedout-froms")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer-sidebar.user-input-data')"
              :to="{ name: 'open_school_users_added_data' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.user-input-data")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListGroup
              :value="$t('summer-sidebar.distribute-supplies-gifts')"
              :fluid="false"
              v-if="role==0||role==1||role==2"
            >
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
                >
                  <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.distribute-supplies-gifts")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-chart-box-outline</v-icon>
                </template>
                </VListItem>
              </template>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'criculms_gifts_supplies' }"
                :value="$t('summer-sidebar.add-statistics')"
              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.add-statistics")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_licenses_records' }"
                :value="$t('summer-sidebar.licenses-records')"
              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.licenses-records")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_levels_curriculums' }"
                :value="$t('summer-sidebar.levels-books')"

              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.levels-books")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_sport_accessories' }"
                :value="$t('summer-sidebar.sport-accessories')"

              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.sport-accessories")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_levels_cards' }"
                :value="$t('summer-sidebar.phases-cards')"

              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.phases-cards")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_certificates' }"
                :value="$t('summer-sidebar.certificates')"

              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.certificates")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
                :to="{ name: 'open_summer_gifts' }"
                :value="$t('summer-sidebar.gifts-prizes')"

              >
                <template v-slot:title>
                  <v-icon size="small" color="icon-color" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.gifts-prizes")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            
          </VListGroup>
          <VListGroup
            class="title border-bottom"
            v-if="role == 0 || role == 1"
            :value="$t('summer-sidebar.mobile-apps')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <dir :title="$t('summer-sidebar.mobile-apps')">
                <VListItem v-bind="props" min-height="40">
                  <template v-slot:title>
                    <span class="v-select-title">{{
                      $t("summer-sidebar.mobile-apps")
                    }}</span>
                  </template>

                  <template v-slot:prepend>
                    <v-tooltip :text="$t('summer-sidebar.mobile-apps')">
                      <template v-slot:activator="{ props }">
                        <v-icon v-show="expand == true" v-bind="props">
                          mdi-cellphone-cog
                        </v-icon>
                        <v-icon v-show="expand == false"
                          >mdi-cellphone-cog
                        </v-icon>
                      </template>
                    </v-tooltip>
                  </template>
                </VListItem>
              </dir>
            </template>
            <VListItem
              :value="$t('summer-sidebar.mobile-versions')"
              :to="{ name: 'mobile_versions' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              v-if="
                CheckGetPermission('summer_center.view_mobileversion', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.mobile-versions")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer-sidebar.upload-apps')"
              :to="{ name: 'upload_applications' }"
              :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              v-if="
                CheckGetPermission('summer_center.view_app', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.upload-apps")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>

          <VListItem
            :value="$t('summer-sidebar.circulars')"
            :to="{ name: 'circulars' }"
            v-if="
              CheckGetPermission('summer_center.view_generalizations', [
                'sys_admin',
                'gen_admin',
              ])
            "
          >
            <!-- v-if="role==0 || role ==1" -->
            <template v-slot:title>
              <span class="v-select-title">{{
                $t("summer-sidebar.circulars")
              }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="small">mdi-bullhorn-outline</v-icon>
            </template>
          </VListItem>
        </div>
      </VList>
    </div>
  </VNavigationDrawer>
  <!-- end side bar  -->
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GeneralManagementReport from "./GeneralManagementReport";
import SummerCenterReport from "./SummerCenterReport";
import GovernorateReport from "./GovernorateReport";
import DirectorateReport from "./DirectorateReport";
import DailyReports from "./DailyReports";
// document.addEventListener('click',dr)
export default {
  props: {
    // drawer: Boolean,
    direction: String,
    lang: String,
  },
  components: {
    GeneralManagementReport,
    SummerCenterReport,
    GovernorateReport,
    DirectorateReport,
    DailyReports,
  },
  data() {
    return {
      selected_list: [],
      selectedList: [],
      expand: false,
      clickOpen: false,
      tempOpenLists: [],
      summer_center: localStorage.getItem("summer_center"),
      collapse: this.$store.state.drawer,
      open: [],
      temp: [],
      clean: [0],
      isOpen: false,
      logo: undefined,
      name: undefined,
      school_type: null,
    };
  },

  computed: {
    ...mapGetters(["getdrawer"]),
    ...mapState(["role"]),
    drawerVal() {
      return this.getdrawer;
    },

    CheckGetPermission() {
      return (prem, role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkpermission(prem, role);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },

    breakPointXS() {
      return this.$vuetify.display.xs;
    },
    breakPointSM() {
      return this.$vuetify.display.sm;
    },
    breakPointMD() {
      return this.$vuetify.display.md;
    },
    breakPointLG() {
      return this.$vuetify.display.lg;
    },
  },
  watch: {
    selectedList(value) {
      this.setBreadcrumb();
    },
    getdrawer() {
      this.collapse = this.getdrawer;
    },
    collapse() {
      this.$store.commit("updateDrawer", this.collapse);
    },
  },
  mounted() {
    var active_elements = document.querySelectorAll(".v-list-item--active");
    if (active_elements.length > 0) {
      const active_list_item = active_elements[
        active_elements.length - 1
      ].querySelector(
        ".v-list-item__content .v-list-item-title span"
      ).innerHTML;
      this.setBreadcrumb(active_list_item);
    } else {
      this.setBreadcrumb();
    }
  },
  async created() {
    window.addEventListener("beforeunload", this.BeforeReload);
    if (this.$vuetify.display.xs || this.$vuetify.display.sm) {
      this.$store.commit("updateDrawer", false);
      this.collapse = false;
    }
    // this.collapse=this.$store.state.drawer
    this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
    if (this.role == 4) {
      await this.axios
        .get(`${this.base_url}api/summer/school/info`)
        .then((response) => {
          this.school_type = response.data?.school_type;
        });
    }

    // this.drawerVal=this.getdrawer
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.BeforeReload);
  },
  methods: {
    BeforeReload() {
      this.selectedList.forEach((item) => {
        localStorage.setItem("selected_list", item);
      });
    },
    setBreadcrumb(active = null) {
      let items = [];
      items = Object.values(this.open).reverse();
      if (active != null) {
        items.push(active);
        this.$store.commit("updateBreadcrumbValue", items);
      } else if (this.selectedList.length > 0) {
        this.selectedList.forEach((item) => {
          items.push(item);
        });
        this.$store.commit("updateBreadcrumbValue", items);
      }
    },
    Expand() {
      this.show = !this.show;
      if (this.expand == false) {
        this.tempOpenLists = this.open;
        this.open = [];
      } else {
        if (this.open[0] != 0) {
          this.open = this.tempOpenLists;
        }
      }
      this.expand = !this.expand;
    },
    changeDrawer() {
      this.$store.commit("updateDrawer", !this.drawerVal);
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>
