<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalNumberOfStudentsInGovernoratesByLevel") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalNumberOfStudentsInGovernoratesByLevel") }}
            </v-sheet>
          </v-col>
          <v-col cols="auto">
            <v-sheet :class="`chart-label-arrow-${this.$i18n.locale} text-center`">
              <h4>{{results.total}}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet color="black" height="6" width="6" style="border-radius: 50%">
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item class="px-5">
      <v-col>
   
          <table class="report-t">
            <thead>
              <tr>
                <th rowspan="2" style="width: 50px;">{{ $t("globals.#") }}</th>
                <th rowspan="2">{{ $t("reportpublic.Governorate") }}</th>
                <th v-if="results.header" :colspan="results.header.length">
                  {{ $t("reportpublic.LevelsName") }}
                </th>
              </tr>
              <tr>
                <th v-for="(val, key) in results.header" :key="key">
                     {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(result,index) in results.data" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{
                    this.$i18n.locale == "ar"
                      ? result.governorate_name_ar
                      : result.governorate_name_en
                  }}
                </td>
                <td v-for="data in result.dataLevel" :key="data" class="text-center">
                  {{ data.count }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" rowspan="2">{{ $t("reportpublic.GrandTotal") }}</th>
                <th v-for="(val, key) in results.header" :key="key">
                  {{ val.total_student }}
                </th>
              </tr>
            </tfoot>
          </table>
        
      
    
        
          <v-card style="height: 400px">
            <chart :option="option_10" autoresize />
          </v-card>
        </v-col>
 
    </v-card-item>
  </v-card>
</template>
<script>
import * as echarts from "echarts";
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.fetchData2();

      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
    this.option_10 = {
      title: {
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 18,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        formatter: function (params) {
          return (
            "<h5>" +
            params.name +
            "</h5>" +
            params.value +
            "&nbsp;&nbsp;&nbsp;" +
            params.marker +
            " " +
            params.seriesName
          );
        },
      },

      xAxis: {
        type: "category",
        data: this.datalevel.map((e)=> this.$i18n.locale == "ar"?e.name_ar:e.name_en),
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
      },
      series: this.series,
      legend: {
        bottom: "bottom",
        left: "center",
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
        top: "85%",
      },
      grid: {
        bottom: "20%",
        containLabel: true,
        top: "10%",
      },
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/summer-level-students`)
        .then((response) => {
          this.results = response.data.results;
 
        })
    },
    async fetchData2() {
      await axios
        .get(`${this.base_url}public-reports/summer-level-for-governorata`)
        .then((response) => {
          this.results2 = response.data.results.data;
          this.datalevel = response.data.results.datalevel;
           this.series = this.results2.map((e) => {
            return {
              name:   this.$i18n.locale == "ar" ?e.gove_name_ar:e.gove_name_en,
              type: "bar",
              label: {
                show: true,
                position: "top",
              },
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              data: e.data,
            };
          });
        })
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      option_10: {},
      datalevel: [],
      series: [],
      results: [],
      results2: [],
      header: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
