<template>
  <!-- <v-expansion-panels class="mb-2" >
    <v-expansion-panel  elevation="1" >
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels> -->
  <v-card class="pb-3 px-2 mb-2">
    <v-card-title>{{$t('globals.select-criteria')}}</v-card-title>
          <v-row class="py-2" >
            <v-col cols="12" md="3" >
              <VAutocomplete
                :label="$t('log-entries.user')"
                item-title="username"
                item-value="id"
                :items="users_list"
                v-model="fk_user"
                persistent-hint
                density="compact"
                clearable
                hide-details="auto"
                @update:modelValue="getData(page=1)"
              ></VAutocomplete>
            </v-col>
            <v-col cols="12" md="3" >
              <VAutocomplete
                :label="$t('log-entries.action')"
                item-title="val"
                item-value="id"
                :items="[{id:1,val:$t('permission.add')},{id:2,val:$t('permission.change')},{id:3,val:$t('permission.delete')},]"
                type="data"
                v-model="action_id"
                persistent-hint
                density="compact"
                clearable
                hide-details="auto"
                @update:modelValue="getData(page=1)"
              ></VAutocomplete>
            </v-col>
            <v-col cols="12" md="3" >
              <VAutocomplete
                :label="$t('globals.content-type')"
                item-title="name"
                item-value="id"
                :items="contentTypes"
                v-model="contentTypeId"
                persistent-hint
                density="compact"
                clearable
                hide-details="auto"
                @update:modelValue="getData(page=1)"
              ></VAutocomplete>
            </v-col>
            <v-col cols="12" md="3" >
              <DatetimePicker  
                v-model="date"
                :calendar="'hijri'"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('globals.date')"
                @update:modelValue="date?.length>9?getData(page=1):null"
              ></DatetimePicker>
            </v-col>
          </v-row>
        </v-card>
  <v-card>
    <v-card-text>
        <!-- ######## Header Of Data Table Server ####### -->
        <div
            class="d-flex justify-space-between mb-1"
            >
            <div class="d-flex" style="flex: 10">
                <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                    <v-icon style="color: #5e7e96" class="me-2"
                        >mdi-eye-settings-outline</v-icon
                    >
                    <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                    <v-checkbox
                        v-for="header in headers"
                        :key="header.key"
                        :label="header.title"
                        :value="header.key"
                        color="indigo"
                        v-model="selectedHead"
                        density="compact"
                        hide-details
                    >
                    </v-checkbox>
                    </v-card-text>
                </v-card>
                </v-menu>

                <v-text-field
                color="indigo"
                v-model="txt_search"
                style="
                    border-radius: 30px;
                    border: 1px solid #ccc;
                    color: black;
                    height: 30px;
                    width: 90%;
                "
                class="search"
                @input="getData(page=1)"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                >
                </v-text-field>
            </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <!-- Data Table  -->
        <v-card  :loading="loading" :disabled="loading">
          <v-data-table-server
            :headers="selectedHeaders"
            :items="log_entries"
            fixed-header
            :height="log_entries?.length>10?400:null"
            item-key="id"
            fixed-footer
            density="compact"
            class="elevation-1"
            id="vDataTable"
            v-model:sort-by="sortBy"
          >
          <template #item.object_repr="{ item }">
              {{ substring(item.value.object_repr,40)}}
          </template>
          <template #item.action_flag="{ item }">
            <v-chip v-if="item.value.action_flag==1" color="indigo">
                  {{ $t('permission.add') }}
                  <template v-slot:append>
                    <v-icon color="indigo">mdi-circle-medium</v-icon>
                  </template>
            </v-chip>
            <v-chip v-else-if="item.value.action_flag==2" color="success">
                {{ $t('permission.change') }}
                <template v-slot:append>
                    <v-icon color="success">mdi-circle-medium</v-icon>
                  </template>
            </v-chip>
            <v-chip v-else-if="item.value.action_flag==3" color="error"  >
                  {{ $t('permission.delete') }}
                  <template v-slot:append>
                    <v-icon color="error">mdi-circle-medium</v-icon>
                  </template>
            </v-chip>
          </template>
          <template #bottom>
              <div class="d-flex" id="pagination-bar">
                <v-pagination
                  v-model="page"
                  :length="pagination.num_pages"
                  density="compact"
                  show-first-last-page
                >
                </v-pagination>
                <div class="d-flex">
                  <v-select
                    v-model="perPage"
                    class="pa-0"
                    :items="itemsPerPage"
                    item-value="value"
                    item-title="text"
                    density="compact"
                    hide-details
                    variant="text"
                  >
                  </v-select>
                  <span class="mt-2 px-2"
                    >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                    {{ pagination.count }} ] {{ $t("globals.item") }}</span
                  >
                </div>
              </div>
            </template>
          </v-data-table-server>
        </v-card>
        <!-- End data Table  -->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getData()
      await this.getUsersList()
      await this.getContentTypes()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      loading: false,
      txt_search: null,
      log_entries:[],
      contentTypes:[],
      users_list:[],
      loading: false,
      sortBy: [{ key: "-action_time", order: "asc" }],
      selectedHead: ["user","content_type","object_repr","action_time","action_flag",],
      headers: [
        { title: this.$t("log-entries.user"), key: "user" },
        { title: this.$t("log-entries.content-type"), key: "content_type" },
        { title: this.$t("log-entries.record"), key: "object_repr" },
        { title: this.$t("log-entries.action-time"), key: "action_time" },
        { title: this.$t("log-entries.action"), key: "action_flag"},
      ],
      page: 1,
      action_id:null,
      fk_user:null,
      date:null,
      contentTypeId:null,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({}),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "-action_time";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
    }),
    async getData(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}api/log-entry`, {
        params: {
            page:page,
            page_size:this.perPage,
            txt_search:this.txt_search,
            order_by:this.order_data,
            fk_user:this.fk_user,
            action_id:this.action_id,
            content_type:this.contentTypeId,
            date : this.date
        },
      }).then((response) => {
        this.loading = false;
        this.log_entries=response.data.results
        this.pagination=response.data.pagination
        this.itemsPerPage[4].value=this.pagination.count
      }).catch(error=>{
        this.loading=false
      });
    },
    async getUsersList() {
      await this.axios(`${this.base_url}userdata/user-data/`).then((response) => {
        this.users_list=response.data
      })
    },
    async getContentTypes() {
      await this.axios(`${this.base_url}api/content-types`).then((response) => {
        this.contentTypes=response.data
      })
    },
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
    order_data(){
      this.getData(this.page);
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span{
  font-size: 11px;
}
tbody td{
  font-size: 11px;
}
</style>
