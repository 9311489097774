<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.StudentStatisticsForAllCenters") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.StudentStatisticsForAllCenters2") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
              height="25"
            >
              <h4>{{ results.student_count }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
   
    

    <v-card-item  v-if="results.students.length > 0">
      <table class="report-t">
        <thead>
          <tr>
            <th style="width:50px">{{$t('globals.#')}}</th>
            <th>{{ $t("reportpublic.student_id") }}</th>
            <th>{{ $t("reportpublic.student-name") }}</th>
            <th>{{ $t("reportpublic.school") }}</th>
            <th>{{ $t("reportpublic.Level") }}</th>
            <th>{{ $t("reportpublic.Gender") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in results.students" :key="index">
            <td>{{index+1}}</td>
            <td>{{ data.student_no }}</td>
            <td>{{ data.student_name }}</td>
            <td>
              {{
                this.$i18n.locale == "ar"
                  ? data.school_name_ar
                  : data.school_name_en
              }}
            </td>
            <td>
              {{
                this.$i18n.locale == "ar"
                  ? data.student_level_ar
                  : data.student_level_en
              }}
            </td>
            <td>{{ data.student_gender }}</td>
          </tr>
        </tbody>
      </table>
    </v-card-item>
       <v-card-item v-else>
    
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
          </v-card-item>
  </v-card>
</template>

<script>
import Directorate from "../../Summer/directorate/Directorate";
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
   async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await axios
        .get( `${this.base_url}public-reports/directorate-reports/students-summer?directorate_id=${this.id}`)
        .then((response) => {
          this.results = response.data.results;
        })
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      id: 5,
      results: {
        "students":[]
      },
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    Directorate,
    ReportHeader,
  },
};
</script>
