<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.OverallSummaryForNumberOfSchools") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bi
                nd="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list id="print-menu" density="compact">
              <v-list-item @click="printTable((all = false))">{{
                $t("globals.shown-records")
              }}</v-list-item>
              <v-list-item @click="printTable((all = true))">{{
                $t("globals.all-records")
              }}</v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalSchools") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results2.gov_name_ar
                  : results2.gov_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results2.summer_count }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row>
          <v-col>
            <table class="report-table text-center">
              <thead>
                <tr>
                  <th rowspan="2">
                    {{ $t("reportpublic.directorate") }}
                  </th>
                  <th colspan="3" class="main-header">
                    {{ $t("reportpublic.centers") }}
                  </th>
                  <th colspan="3" class="main-header">
                    {{ $t("reportpublic.StudentsMalesFemales") }}
                  </th>
                  <th colspan="3" class="main-header">
                    {{ $t("reportpublic.WorkersMalesFemales") }}
                  </th>
                </tr>
                <tr>
                  <th>{{ $t("reportpublic.Cloes") }}</th>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                  <th>{{ $t("reportpublic.Cloes") }}</th>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                  <th>{{ $t("reportpublic.Cloes") }}</th>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(summer, key) in results.data" :key="key">
                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? summer.directorate_name_ar
                        : summer.directorate_name_en
                    }}
                  </td>
                  <td>{{ summer.number_school_closed }}</td>
                  <td>{{ summer.number_school_open }}</td>
                  <td>{{ summer.number_school_typical }}</td>
                  <td>{{ summer.number_student_school_closed }}</td>
                  <td>{{ summer.number_student_school_open }}</td>
                  <td>{{ summer.number_student_school_typical }}</td>
                  <td>{{ summer.number_employee_school_closed }}</td>
                  <td>{{ summer.number_employee_school_open }}</td>
                  <td>{{ summer.number_employee_school_typical }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="results.total">
                  <th class="rounded-be-lg">
                    {{ this.$t("reportpublic.GrandTotal") }}
                  </th>
                  <th>
                    {{ results.total.total_school_closed }}
                  </th>
                  <th>
                    {{ results.total.total_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_school_typical }}
                  </th>
                  <th>
                    {{ results.total.total_employee_school_closed }}
                  </th>
                  <th>
                    {{ results.total.total_employee_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_employee_school_typical }}
                  </th>
                  <th>
                    {{ results.total.total_student_school_closed }}
                  </th>
                  <th>
                    {{ results.total.total_student_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_student_school_typical }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async mounted() {
    await this.fetchData();
    await this.fetchData2();
  },
  methods: {
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/overall-summary-for-number-of-schools`)
        .then((response) => {
          this.results = response.data.results;
        });
    },
    async fetchData2() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/overall-summary-for-number-of-schools-chart` )
        .then((response) => {
          this.results2 = response.data.results;
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      results: [],
      results2: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
