<template>
  <v-card id="report" class="pa-8">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              $t("reportpublic.ReportOnNumbersOfOpenSchoolsStaffAndStudents")
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="mb-5 pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t("reportpublic.ReportOnNumbersOfOpenSchoolsStaffAndStudents")
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ date.slice(0, 4) + " هـ" }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item class="exPrint">
      <v-container>
        <v-row>
          <v-col
            cols="5"
            style="background: #bad7e9; height: 85%"
            class="ps-0 pt-0 py-5 rounded"
          >
            <v-card
              :title="$t('reportpublic.NumberOfDirectorates')"
              :class="`mt-4 text-white ${
                this.$i18n.locale == 'ar' ? 'rounded-s-xl' : 'rounded-e-xl'
              }`"
              style="background: black"
            ></v-card>
            <v-card
              :title="
                $t('reportpublic.SummerSchoolsForTheYear') +
                ' ' +
                date.slice(0, 4) +
                ' هـ'
              "
              :class="`mt-4 text-white ${
                this.$i18n.locale == 'ar' ? 'rounded-s-xl' : 'rounded-e-xl'
              }`"
              style="background: #dc5f00"
            ></v-card>
            <v-card
              :title="`${$t(
                'reportpublic.TheAverageNumberOfTeachersPerSchoolIs4'
              )} ${(
                results.teacher_summer_open_counts /
                results.school_summer_open_counts
              ).toFixed(2)}
              ${$t('reportpublic.Teachers')}
              `"
              :class="`mt-4 text-white ${
                this.$i18n.locale == 'ar' ? 'rounded-s-xl' : 'rounded-e-xl'
              }`"
              style="background: #1b4242"
            ></v-card>
            <v-card
              :title="
                $t('reportpublic.NumberOfActivitiesOfficialsInTheGovernorate')
              "
              :class="`mt-4 text-white ${
                this.$i18n.locale == 'ar' ? 'rounded-s-xl' : 'rounded-e-xl'
              }`"
              style="background: #5aa469"
            ></v-card>
            <v-card
              :title="$t('reportpublic.NumberOfPrincipalsOfOpenSchools')"
              :class="`mt-4 text-white ${
                this.$i18n.locale == 'ar' ? 'rounded-s-xl' : 'rounded-e-xl'
              }`"
              style="background: #d35d6e"
            ></v-card>
          </v-col>
          <v-col cols="2">
            <v-card
              class="mt-0 pt-1 text-white rounded-pill text-center"
              height="100%"
              width="60%"
              min-width="max-content"
              style="background: #bad7e9"
            >
              <v-card-item style="color: black" class="mt-4">
                <v-card-title class="text-h6 font-weight-bold">{{
                  results.directorate_counts
                }}</v-card-title>
              </v-card-item>
              <v-card-item style="color: #dc5f00" class="mt-4">
                <v-card-title class="text-h6 font-weight-bold">{{
                  results.school_summer_open_counts
                }}</v-card-title>
              </v-card-item>
              <v-card-item style="color: #1b4242" class="mt-4">
                <v-card-title class="text-h6 font-weight-bold">{{
                  results.teacher_summer_open_counts
                }}</v-card-title>
              </v-card-item>
              <v-card-item style="color: #5aa469" class="mt-4">
                <v-card-title class="text-h6 font-weight-bold">{{
                  results.activty_officer_summer_open_counts
                }}</v-card-title>
              </v-card-item>
              <v-card-item style="color: #d35d6e" class="mt-4">
                <v-card-title class="text-h6 font-weight-bold">{{
                  results.manager_summer_open_counts
                }}</v-card-title>
              </v-card-item>
            </v-card>
          </v-col>
          <v-col cols="5" class="align-center mt-0">
            <v-card class="px-2 pt-1">
              <div style="height: 290px">
                <chart :option="option_students" autoresize />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

const legend = {
  show: true,
  top: "30px",
  left: "left",
  orient: "vertical",
  textStyle: { fontFamily: "Almarai" },
};
const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.seriesName;
    var percent = params.percent;
    var value = params.value;
    return name + "<br/>" + value + " (" + percent * 2 + "%)";
  },
};
export default {
    data() {
    return {
      series_students: [],
      option_students: {},
      results: [],
      male_count:0,
      students: {
      
      },
      date: "",
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();   
 
    } catch (erroe) {
     
    }
     this.series_students = [
      {
        top: "5%",
        bottom: "15%",
        type: "pie",
        radius: ["20%", "80%"],
        name: this.$t("globals.students"),
        center: ["50%", "70%"],
        data: [
          { value:this.male_count, name: "المدارس الصيفية لعام 1442" },
          {
            value: 20,
            name: "المدارس المتوقعة لعام 1442",
          },
        ],
        label: {
          show: true,
          position: "inside",
          formatter: "{c}",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: "30px",
          },
        },
      },
    ];
    this.option_students = {
      title: [
        {
          text: "تقدير اعداد المدارس المفتوحة والعاملين والطلاب",
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 12,
          },
        },
      ],
      legend: legend,
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      series: this.series_students,
      tooltip: tooltip,
    };
     await this.$store.commit("updateLoadingValue", false);
  },

  methods: {
    async fetchData() {
     await  axios
        .get(`${this.base_url}public-reports/summer-opened-general-details`)
        .then((response) => {
          this.results = response.data.results;
          this.male_count = this.results.school_summer_open_counts;
 
          // this.date = response.data.results.current_date;                
         })
        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  components: {
    ReportHeader,
  },
  watch:{
      students(){
        return   this.students;
      }
  }
};
</script>
