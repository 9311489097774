<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalStudentsByLevel") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
        <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalStudentsByLevel") }}
              {{ $t("reportpublic.InDirectorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.directorate_name_ar
                  : results.directorate_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ results.total_summers }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item>
      <v-container class="overflow-x-hidden" v-if="data.length>0">
       
          <table class="report-t">
            <thead>
              <tr>
                <th rowspan="2" style="width:50px">{{$t('globals.#')}}</th>
                <th rowspan="2">{{ $t("reportpublic.CenterName") }}</th>
                <th :colspan="totals.length">
                  {{ $t("reportpublic.LevelsName") }}
                </th>
              </tr>
              <tr>
                <th v-for="(val, key) in totals" :key="key">
                  {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(val, key) in data" :key="key">
           <tr>
               <td>{{key+1}}</td>
              <td>
                {{
                  this.$i18n.locale == "ar"
                    ? val.school_summer_name_ar
                    : val.school_summer_name_en
                }}
              </td>
              <td v-for="(v, key) in val.dataLevel" :key="key" class="text-center">
                {{ v.count }}
              </td>
           </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">
                  {{ $t("reportpublic.GrandTotal") }}
                </th>
                <th  v-for="(val, key) in totals" :key="key">
                  {{ val.count }}
                </th>
              </tr>
            </tfoot>
          </table>
      
      </v-container>
         <v-card-item v-else>
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card-item>
      <v-row>
        <v-col class="mt-4 mb-4">
          <v-card style="background: #bad7e9; height: 120%">
            <v-container
              style="gap: 0.5em"
              class="d-flex justify-space-evenly flex-wrap flex-shrink-0"
            >
              <div v-for="(val, key) in totals" :key="key">
                <v-card-title
                  class="text-white text-center py-2 pt-3"
                  style="
                    background: linear-gradient(30deg, #c9485b, #4c0027);
                    clip-path: polygon(
                      100% 50%,
                      75% 93.3%,
                      25% 93.3%,
                      0 50%,
                      25% 6.7%,
                      75% 6.7%
                    );
                    width: 120px;
                    height: 50px;
                    aspect-ratio: 1;
                  "
                >
                  {{
                    this.$i18n.locale == "ar"
                      ? val.level_name_ar
                      : val.level_name_en
                  }}</v-card-title
                >
                <v-card-title
                  class="rounded-pill text-center text-white"
                  style="
                    background: linear-gradient(30deg, #c9485b, #4c0027);
                    width: 120px;
                  "
                  >{{ val.count }}
                </v-card-title>
              </div>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
;

export default {
 async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/directorate-reports/student-by-levels`)
        .then((response) => {
          this.results = response.data;
          this.data = response.data.results.school_summer;
          this.totals = response.data.results.totals;
        })
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  data() {
    return {
      results: [],
      size: null,
      data: [],
      totals: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
