import axios from "axios";
import store from "@/store/index.js";
import {decryptString} from '@/utils/index.js'



const custom_axios = axios.create({
    timeout: 600000,
});
  custom_axios.interceptors.request.use(
      function (config) {
          return config;
        },
        function (error) {
            return Promise.reject(error);
    }
    );
    custom_axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if(error.message=="Network Error"){
                store.commit('setErrorConnection',true)
            }else if(error.message.includes("timeout")) {
        store.commit('setTimeoutAlert',true)
      }
      return Promise.reject(error);
    }
  );

  const cipher_token = localStorage.getItem("token")
  if(cipher_token){
    const token = decryptString(cipher_token)
    custom_axios.defaults.headers.common['Authorization']=`Bearer ${token}`
  }
export default custom_axios;