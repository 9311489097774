<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.SummerTotalActivitiesCarriedOutHeader") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>

      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t("reportpublic.ATotal") +
                " " +
                $t("reportpublic.SummerTotalActivitiesCarriedOut")
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ totals }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-item>
      <v-container>
        <v-row>
          <table class="report-t">
            <thead>           
              <tr>        
                <th rowspan="2" style="width: 50px;">{{$t("globals.#")}}</th>
                <th rowspan="2" >{{ $t("reportpublic.Governorate") }}</th>
                <th :colspan="headers.length" >{{$t("permission.activitytype")}}</th>                
                <th rowspan="2" >{{ this.$t("reportpublic.Total") }}</th>
              </tr>
              <tr>
                <th v-for="header in headers" :key="header">
                  {{ header.name }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item,index) in results" :key="index">
              <tr>
                <td>{{index+1}}</td>
                <td>{{ item.name_gov }}</td>
                <td v-for="i in item.data" :key="i" class="text-center">{{ i }}</td>
                <td class="text-center">{{ item.total }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2">{{ $t("reportpublic.GrandTotal") }}</th>
                <th v-for="header in headers" :key="header">
                  {{ header.total }}
                </th>
                <th>{{ totals }}</th>
              </tr>
            </tfoot>
          </table>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/daily-reports/summer-total-activities-carried-out`)
        .then((response) => {
          this.results = response.data.results;
          this.headers = response.data.headers;
          this.totals = response.data.totals;
        })

        .catch((error) => {});
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: [],
      headers: [],
      totals: 0,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  components: {
    ReportHeader,
  },
};
</script>
