<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.TotalSummaryTypicalPositionsMFGovernorate") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
         <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalTypicalCenters") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results.gov_name_ar
                  : results.gov_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
              height="25"
            >
              <h4>{{ results["summer_typical_counts"] }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="12">
          <v-card class="pt-3 px-1" id="card" style="height: 300px">
            <div id="studentStatistics"></div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="flex-chart">
        <v-col>
          <v-card class="px-2 pt-1 my-4">
            <div style="height: 270px">
              <chart :option="total_schools" autoresize />
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="px-2 pt-1 my-4">
            <div style="height: 270px">
              <chart :option="total_students" autoresize />
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="px-2 pt-1 my-4">
            <div style="height: 270px">
              <chart :option="total_workers" autoresize />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import * as echarts from "echarts";
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

const legend = {
  show: true,
  top: "30px",
  left: "left",
  orient: "vertical",
  textStyle: { fontFamily: "Almarai" },
};
const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.name;
    var percent = params.percent;
    var value = params.value;
    return params.marker + " " + name + "<br/>" + value + " (" + percent + "%)";
  },
};

export default {
  async mounted() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
    this.series_total_workers = [
      {
        top: "50px",
        bottom: "50px",
        type: "pie",
        radius: ["1%", "99%"],
        center: ["50%", "70%"],
        data: [
          {
            value: this.results["males_workers_typical_counts"],
            name: this.$t("reportpublic.MalesWorkers"),
          },
          {
            value: this.results["females_workers_typical_counts"],
            name: this.$t("reportpublic.FemalesWorkers"),
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0,0,0,0.5)",
          },
        },
        label: {
          show: true,
          position: "inside",
          formatter: (params) =>
            this.labelFormatter(params, this.results["workers_typical_counts"]),
        },
      },
    ];
    this.total_workers = {
      title: [
        {
          text: this.$t("reportpublic.TotalWorkrs"),
          subtext: `${this.results["workers_typical_counts"]}`,
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 18,
          },
        },
      ],
      legend: legend,
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      series: this.series_total_workers,
      tooltip: tooltip,
    };

    this.series_total_schools = [
      {
        top: "50px",
        bottom: "50px",
        type: "pie",
        radius: ["1%", "99%"],
        center: ["50%", "70%"],
        data: [
          {
            value: this.results["summer_typical_for_males_counts"],
            name: this.$t("reportpublic.Males"),
          },
          {
            value: this.results["summer_typical_for_females_counts"],
            name: this.$t("reportpublic.Females"),
          },
          {
            value: this.results["summer_typical_for_general_counts"],
            name: this.$t("reportpublic.Generl"),
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0,0,0,0.5)",
          },
        },
        label: {
          show: true,
          position: "inside",
          formatter: (params) =>
            this.labelFormatter(params, this.results["summer_typical_counts"]),
        },
      },
    ];
    this.total_schools = {
      title: [
        {
          text: this.$t("reportpublic.TotalSchools"),
          subtext: `${this.results["summer_typical_counts"]}`,
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 18,
          },
        },
      ],
      legend: legend,
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      series: this.series_total_schools,
      tooltip: tooltip,
    };

    this.series_total_students = [
      {
        top: "50px",
        bottom: "50px",
        type: "pie",
        radius: ["1%", "99%"],
        center: ["50%", "70%"],
        data: [
          {
            value: this.results["males_students_typical_counts"],
            name: this.$t("reportpublic.MaleStudents"),
          },
          {
            value: this.results["females_students_typical_counts"],
            name: this.$t("reportpublic.FemaleStudents"),
          },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0,0,0,0.5)",
          },
        },
        label: {
          show: true,
          position: "inside",
          formatter: (params) =>
            this.labelFormatter(
              params,
              this.results["students_typical_counts"]
            ),
        },
      },
    ];
    this.total_students = {
      title: [
        {
          text: this.$t("reportpublic.TotalStudents"),
          subtext: `${this.results["students_typical_counts"]}`,
          left: "center",
          textStyle: {
            fontFamily: "Almarai",
            fontSize: 18,
          },
        },
      ],
      legend: legend,
      toolbox: {
        feature: {
          saveAsImage: {
            title: this.$t("dashboard.save-as-png"),
          },
        },
      },
      series: this.series_total_students,
      tooltip: tooltip,
    };
    this.option_2 = {
      title: {
        text: this.$t("reportpublic.TotalTypicalCenters"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 18,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      xAxis: {
        type: "category",
        data: this.results.directorates.map((item) =>
          this.$i18n.locale == "ar"
            ? item.directorate_name_ar
            : item.directorate_name_en
        ),
        axisLabel: {
          fontSize: 14,
          color: "black",
          fontWeight: "bold",
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
      },
      series: [
        {
          label: {
            show: true,
            rotate: 90,
          },

          // barWidth: "23px",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          type: "bar",
          data: this.results.directorates.map((item) => {
            return { value: item.counts };
          }),
        },
      ],

      tooltip: {
        formatter: function (params) {
          return (
            params.value +
            "&nbsp;&nbsp;&nbsp;" +
            params.marker +
            " " +
            params.name
          );
        },
      },
    };
    this.printChart();
    const appIconMenu = document.getElementById("appIconMenu");
    appIconMenu.addEventListener("click", () => {
      // this.printChart()
      const chartElement = document.getElementById("studentStatistics");
      const chart = echarts.getInstanceByDom(chartElement);
      setTimeout(() => {
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth,
          height: element.offsetHeight,
        });
      }, 10);
    });
    window.addEventListener("resize", this.printChart);
  },
  methods: {
    printChart() {
      const chartElement = document.getElementById("studentStatistics");
      const chart = echarts.init(chartElement);
      chart.setOption(this.option_2);
      const element = document.getElementById("card");
      chart.resize({
        width: element.offsetWidth - 12,
        height: element.offsetHeight,
      });
    },
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/overall-summary-for-typical-courses`)
        .then((response) => {
          this.results = response.data.results;
        })

        .catch((error) => {
            
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
    labelFormatter(params, total) {
      if (total) {
        return params.value ? params.value : "";
      } else {
        return params.value;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      series_total_workers: {},
      total_workers: {},
      series_total_students: {},
      total_students: {},
      series_total_schools: {},
      total_schools: {},
      statistics: [],
      option_2: {},
      size: null,

      students: {
        male: 10,
        female: 20,
      },
      results: [],
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
