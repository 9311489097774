<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              $t("reportpublic.OverallSummaryForNumberOfSchools") +
              " " +
              $t("reportpublic.InTheGovernorate")
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pl-0">
            <v-sheet class="px-2 chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.TotalSchools") }}
              {{ $t("reportpublic.InGovernorate") }}
              {{
                this.$i18n.locale == "ar"
                  ? results2.gov_name_ar
                  : results2.gov_name_en
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>{{ results2.summer_count }}</h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
 
            <table class="report-t">
              <thead>
                <tr>
                   <th rowspan="2" style="width:50px">{{ $t("globals.#") }}</th>

                  <th rowspan="2">
                    {{ $t("reportpublic.directorate") }}
                  </th>
                  <th colspan="2">
                    {{ $t("reportpublic.centers") }}
                  </th>
                  <th colspan="2">
                    {{ $t("reportpublic.StudentsMalesFemales") }}
                  </th>
                  <th colspan="2">
                    {{ $t("reportpublic.WorkersMalesFemales") }}
                  </th>
                </tr>
                <tr>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                  <th>{{ $t("reportpublic.Open") }}</th>
                  <th>{{ $t("reportpublic.Typical") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(summer, key) in results.data" :key="key">
                  <td>{{key+1}}</td>
                  <td>
                    {{
                      this.$i18n.locale == "ar"
                        ? summer.directorate_name_ar
                        : summer.directorate_name_en
                    }}
                  </td>
                  <td class="text-center">{{ summer.number_school_open }}</td>
                  <td class="text-center">{{ summer.number_school_typical }}</td>
                  <td class="text-center">{{ summer.number_student_school_open }}</td>
                  <td class="text-center">{{ summer.number_student_school_typical }}</td>
                  <td class="text-center">{{ summer.number_employee_school_open }}</td>
                  <td class="text-center">{{ summer.number_employee_school_typical }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="results.total">
                  <th colspan="2">
                    {{ this.$t("globals.total") }}
                  </th>
                
                  <th>
                    {{ results.total.total_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_school_typical }}
                  </th>
        
                  <th>
                    {{ results.total.total_student_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_student_school_typical }}
                  </th>
               
                  <th>
                    {{ results.total.total_employee_school_open }}
                  </th>
                  <th>
                    {{ results.total.total_employee_school_typical }}
                  </th>
                </tr>
                <tr v-if="results.total">
                  <th colspan="2">
                    {{ this.$t("reportpublic.GrandTotal") }}
                  </th>
                
                  <th colspan="2">
                    {{ results.total.total_school_open +results.total.total_school_typical }}
                  </th>
                  
        
                  <th colspan="2">
                    {{ results.total.total_student_school_open +results.total.total_student_school_typical  }}
                  </th>                  
               
                  <th colspan="2">
                    {{ results.total.total_employee_school_open + results.total.total_employee_school_typical }}
                  </th>
                  
                </tr>
                
                
              </tfoot>
            </table>
        
      <v-row class="flex-chart" v-if="results">
        <v-col v-for="(data, index) in types_data" :key="index">
          <overall-summary-for-number-of-schools-chart
            :chart-data="data"
          ></overall-summary-for-number-of-schools-chart>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
;
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import OverallSummaryForNumberOfSchoolsChart from "@/components/reports/OverallSummaryForNumberOfSchoolsChart.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.fetchData2();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async fetchData() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/overall-summary-for-number-of-schools`)
        .then((response) => {
          this.results = response.data.results;
        });
    },
    async fetchData2() {
      await axios
        .get(
          `${this.base_url}public-reports/governorate-reports/overall-summary-for-number-of-schools-chart`)
        .then((response) => {
          this.results2 = response.data.results;
          this.types_data = this.results2.types_data;
        });
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart);
  },
  data() {
    return {
      results: [],
      results2: [],
      types_data: [],
      isGrey: true,
      statistics: [],
      totalOfSchool: {},
      option_3: {},
      size: null,
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
    OverallSummaryForNumberOfSchoolsChart,
  },
};
</script>
